import './App.css';
import React from 'react'
// import axios from 'axios'
import routes from './routes';

const App = () => {
  
  return (

    <div>{routes}</div>

  )
}

export default App